input {
  width: 80%;
  padding: 1.5%;
  margin-bottom: 1.2%;
  margin-top: 1.2%;
  font-family: 'Raleway', sans-serif;
  border-radius: 6px;
  border: none;
  background: #eee;
  outline: none;
}

textarea {
  width: 100%;
  margin-right: 30%;
  margin-bottom: 2%;
  margin-top: 2%;
  font-family: 'Raleway', sans-serif;
  border-radius: 12px;
  border: none;
  background: #eee;
  outline: none;
  height: 6vh;
  overflow: auto;
  resize: none;
  outline: none;
  padding: 2%;
}
label {
  font-weight: bolder;
}

@media only screen and (max-width: 600px) {
  input {
    padding: 4%;
  }
}
