body {
  background-color: #f1efef !important;
  font-family: 'Montserrat', sans-serif;
}

.main-head {
  grid-area: header;
  background: white;
  display: grid;
  justify-content: left;
  align-content: center;
  box-shadow: 0 8px 6px -6px black;
  border-bottom: 2px solid;

  grid-template-columns: 3fr 1fr;
  grid-template-areas: 'header-company header-welcome';
}

.main-head-title {
  align-content: start;
  font-weight: bolder;
  font-size: 20px;
  margin-left: 3%;
  grid-area: header-company;
}
.main-head-welcome {
  align-content: end;
  grid-area: header-welcome;
  color: black;
  margin-right: 3%;
  align-self: end;
}
.content {
  grid-area: content;
  background: #e0e0e0;
  padding: 3%;
  overflow: auto;
}

.content > h1 {
  background: white;
  padding: 2%;
  border-radius: 8px;
}

.content > p {
  background: white;
  padding: 2%;
  margin-top: 2%;
  border-radius: 8px;
}

.main-nav {
  grid-area: nav;
  background: black;
}
.side {
  grid-area: sidebar;
}
.ad {
  grid-area: ad;
  align-self: end;
}
.main-footer {
  grid-area: footer;
}

.wrapper {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 0.4fr 2fr 2fr 2fr;
  grid-template-areas:
    'header header  header'
    'nav    content content'
    'nav    content content'
    'nav    content content';
}

@media only screen and (max-width: 600px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.5fr 0.5fr 2fr 2fr;
    grid-template-areas:
      'header header  header'
      'nav    nav nav'
      'content content content'
      'content content content';
  }
}

.wrapper .main-nav h2 {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.wrapper .main-nav ul li {
  padding: 15px;
  border-bottom: 1px solid #bdb8d7;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.05);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.wrapper .main-nav ul li div {
  color: #bdb8d7;
  display: block;
}

.wrapper .main-nav ul li div .fas {
  width: 25px;
}

.wrapper .main-nav ul li:hover {
  background-color: #594f8d;
}

.wrapper .main-nav ul li:hover a {
  color: #fff;
}

.logout-button {
  background-color: #594f8d;
  padding: 3%;
  margin: 5%;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;
  color: white;
}

.selected-tab {
  background-color: #594f8d;
  font-weight: 700;
}

/*  Car Listing */

.listing-container {
  background-color: white;
  border-radius: 12px;
  padding: 2%;
  display: grid;
  grid-gap: 20px;
  width: 75vw;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'section-left section-right'
    'section-left section-right';
}

@media only screen and (max-width: 600px) {
  .listing-container {
    padding: 5%;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 2fr;
    grid-template-areas:
      'section-left'
      'section-right';
  }
}
.wizard {
}

.section-left {
  grid-area: section-left;
}
.section-right {
  grid-area: section-right;
}
.email-class {
  background-color: red;
}

.submit-button {
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  padding: 1%;
  margin: 1%;
  background: #0e6c2a;
  font-weight: bolder;
  display: inline-block;
  text-decoration: none;
  border: none;
}

.add-button,
.remove-button {
  background-color: rgb(14, 34, 15); /* Green */
  border: none;
  color: white;
  padding: 1%;
  margin: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}

.previous {
  background-color: rgb(14, 34, 15); /* Green */
  border: none;
  color: white;
  padding: 1%;
  margin: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}

.next {
  background-color: rgb(14, 34, 15); /* Green */
  border: none;
  color: white;
  padding: 1%;
  margin: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}
.remove-button {
  background: rgb(255, 15, 15);
}

.add-button:hover,
.submit-button:hover {
  background: #118634;
}

.submit-button:disabled {
  background: #828382;
  color: black;
}

.tags {
  width: 40%;
}

.stepper-header {
  background: white;
  padding: 1.5%;
  width: 100%;
  margin-bottom: 2%;
  border-radius: 8px;
  font-weight: bolder;
}
/* class applies to select element itself, not a wrapper element */
.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 60%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      
  */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled='true'] {
  color: 'gray';
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled='true'] {
  border-color: #aaa;
}

input[type='checkbox'] + label {
  margin: 5%;
  cursor: pointer;
  width: 100%;
  padding: 10%;
}
