body {
  background: #f8f6f6;
  font-family: 'Montserrat', sans-serif;
}

.MuiDrawer-paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  width: 230px;
}

.MuiTabs-flexContainer {
  display: flex;
  background: white;
}
